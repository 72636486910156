section#contact {
    background-color: #212529;
    background-image: url("/src/assets/images/map-image.png");
    background-repeat: no-repeat;
    background-position: center;
}

section#contact .section-heading {
    color: #fff;
}

.form-control {
    height: auto;
    padding: 1.25rem;
}

section#contact form#contactForm .form-group {
    margin-bottom: 1.5rem;
}

section#contact form#contactForm .form-group-textarea textarea {
    height: 100%;
    min-height: 10rem;
}

section#contact form#contactForm .form-group-textarea {
    height: 100%;
}

.btn-xl {
    padding: 1.25rem 2.5rem;
    font-family: 'Montserrat', sans-serif;   font-size: 1.125rem;
    font-weight: 600;
}