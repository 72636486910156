.volli-yellow {
  color: #ffc800;
}

.carousel-container {
  display: flex;
  justify-content: space-around;
}

.carousel-mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.carousel-caption {
  position: initial; /* override */
}

.carousel-general {
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
}

.carousel-mobile-general {
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  width: 100% !important;
  flex-direction: column;
}

.carousel-text {
  width: 60%;
}

.carousel-img {
  height: auto;
  width: 100%;
}

.section {
  padding: 6rem 0;
  text-align: center;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.section-heading {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.section-subheading {
  font-size: 1rem;
  font-style: italic;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-bottom: 4rem;
}

.btn-primary {
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
}

.btn-primary:hover {
  color: #fff;
  background-color: #cca000 !important;
  border-color: #cca000 !important;
}

.btn-primary:focus {
  color: #fff;
  background-color: #ffc800 !important;
  border-color: #cca000 !important;
  box-shadow: none !important;
}

.btn-primary:disabled {
  color: #fff;
  background-color: gray;
  border-color: gray;
  box-shadow: none !important;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500&display=swap");

.text-uppercase {
  text-transform: uppercase !important;
}

.main-wrapper {
  min-height: 100vh;
  text-align: center;
  color: rgb(0, 0, 0);
  background-image: url("/src/assets/images/background.png");
  background-repeat: no-repeat;
  box-shadow: inset 0px 10px 1000px 20px #000;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-fixed {
  background-attachment: fixed;
}

.main-subheading {
  color: #fff;
  font-size: 2.25rem;
  font-style: italic;
  line-height: 2.25rem;
  margin-bottom: 2rem;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
}

.main-button {
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 1.25rem 2.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
  display: inline-block;
  box-sizing: border-box;
  text-transform: uppercase;
}

.main-button:hover {
  color: #cca000;
}

.presentation-title {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  font-size: calc(1.275rem + 0.3vw);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.2;
}

.presentation-description {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

@media all and (min-width: 480px) {
  .emailAndPassContainer {
    background-color: #fff !important;
    padding: 24px !important;
    width: 40%;
    margin: auto;
    border-radius: 10px;
    position: sticky;
  }

  .emailAndPassContainer form {
    margin: 0 auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
  }

  .forgotPass {
    text-decoration: none;
    text-align: right;
  }

  .sign-up {
    text-decoration: none;
    color: black;
    padding-left: 10px;
  }

  .dashboardContainer {
    background-color: #fff !important;
    padding: 24px !important;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    position: sticky;
  }

  .dashboard-title {
    color: black;
    text-align: left;
  }

  .dasbhoard-title-user {
    color: #ffc800;
  }
}

.tabel-button {
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 5px;
  flex: 1;
}

.tabel-button:last-child {
  margin-right: 0px;
}

button:disabled,
button[disabled] {
  background-color: gray;
}

.switch-buttons {
  display: flex;
}

.tabel-button-active {
  box-sizing: border-box;
  box-shadow: 0px -5px 0px black inset;
}

.black-icon {
  color: black;
}

.padding-terms {
  padding: 1rem 0.75rem !important;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:active:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  text-decoration: none !important;
  color: #6c757d;
}

.btn-link,
.btn-link:active,
.btn-link:active:focus {
  background-color: transparent;
  border-color: transparent !important;
  text-decoration: none !important;
}

.btn-link:hover {
  color: #ffc800;
}

.greyed-text {
  color: #6c757d !important;
}

.active-text {
  color: #000 !important;
}
