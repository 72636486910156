.footer {
  background-color: #212529;
  color: #fff;
  padding: 20px 48px;
  text-align: center;
  width: 100%;
}

.green-line {
  height: 10px;
  width: 100%;
  background: linear-gradient(to right, #ffffff, #4ca982);
  margin: 0;
}

.footer-columns {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-column {
  flex-basis: 45%; /* Adjust the width of the columns as needed */
  text-align: left;
  font-size: 14px;
}

.footer-column .bold-text {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px; /* Double the margin between the bold text and anchors */
  display: block;
}

.footer-column a {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
}

.line {
  border: 1px solid #000;
  margin: 20px 0;
}

.copyright-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.logo-image {
  max-width: 40px;
  height: auto;
}

.social-link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 10px;
  font-size: 14px;
}

.social-icon {
  font-size: 24px; /* Adjust the font size of the icons as needed */
  margin-right: 10px; /* Add spacing between icon and text */
}

.copyright {
  font-size: 14px; /* Adjust the font size for the copyright text */
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}
