@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500&display=swap);
.volli-yellow {
  color: #ffc800;
}

.carousel-container {
  display: flex;
  justify-content: space-around;
}

.carousel-mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.carousel-caption {
  position: initial; /* override */
}

.carousel-general {
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
}

.carousel-mobile-general {
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  width: 100% !important;
  flex-direction: column;
}

.carousel-text {
  width: 60%;
}

.carousel-img {
  height: auto;
  width: 100%;
}

.section {
  padding: 6rem 0;
  text-align: center;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.section-heading {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.section-subheading {
  font-size: 1rem;
  font-style: italic;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-bottom: 4rem;
}

.btn-primary {
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
}

.btn-primary:hover {
  color: #fff;
  background-color: #cca000 !important;
  border-color: #cca000 !important;
}

.btn-primary:focus {
  color: #fff;
  background-color: #ffc800 !important;
  border-color: #cca000 !important;
  box-shadow: none !important;
}

.btn-primary:disabled {
  color: #fff;
  background-color: gray;
  border-color: gray;
  box-shadow: none !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.main-wrapper {
  min-height: 100vh;
  text-align: center;
  color: rgb(0, 0, 0);
  background-image: url(/static/media/background.fbdaa7db.png);
  background-repeat: no-repeat;
  box-shadow: inset 0px 10px 1000px 20px #000;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-fixed {
  background-attachment: fixed;
}

.main-subheading {
  color: #fff;
  font-size: 2.25rem;
  font-style: italic;
  line-height: 2.25rem;
  margin-bottom: 2rem;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
}

.main-button {
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  padding: 1.25rem 2.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
  display: inline-block;
  box-sizing: border-box;
  text-transform: uppercase;
}

.main-button:hover {
  color: #cca000;
}

.presentation-title {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  font-size: calc(1.275rem + 0.3vw);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.2;
}

.presentation-description {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

@media all and (min-width: 480px) {
  .emailAndPassContainer {
    background-color: #fff !important;
    padding: 24px !important;
    width: 40%;
    margin: auto;
    border-radius: 10px;
    position: -webkit-sticky;
    position: sticky;
  }

  .emailAndPassContainer form {
    margin: 0 auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
  }

  .forgotPass {
    text-decoration: none;
    text-align: right;
  }

  .sign-up {
    text-decoration: none;
    color: black;
    padding-left: 10px;
  }

  .dashboardContainer {
    background-color: #fff !important;
    padding: 24px !important;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    position: -webkit-sticky;
    position: sticky;
  }

  .dashboard-title {
    color: black;
    text-align: left;
  }

  .dasbhoard-title-user {
    color: #ffc800;
  }
}

.tabel-button {
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 5px;
  flex: 1 1;
}

.tabel-button:last-child {
  margin-right: 0px;
}

button:disabled,
button[disabled] {
  background-color: gray;
}

.switch-buttons {
  display: flex;
}

.tabel-button-active {
  box-sizing: border-box;
  box-shadow: 0px -5px 0px black inset;
}

.black-icon {
  color: black;
}

.padding-terms {
  padding: 1rem 0.75rem !important;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:active:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  text-decoration: none !important;
  color: #6c757d;
}

.btn-link,
.btn-link:active,
.btn-link:active:focus {
  background-color: transparent;
  border-color: transparent !important;
  text-decoration: none !important;
}

.btn-link:hover {
  color: #ffc800;
}

.greyed-text {
  color: #6c757d !important;
}

.active-text {
  color: #000 !important;
}

.navbar {
  position: fixed;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: none;
  background-color: transparent;
  transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  z-index: 1000;
}

.navbar-light .navbar-toggler {
  color: #ffc800;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border-color: #ffc800;
}

.navbar-toggler-icon {
  -webkit-filter: invert(85%) sepia(15%) saturate(7435%) hue-rotate(359deg)
    brightness(101%) contrast(105%);
          filter: invert(85%) sepia(15%) saturate(7435%) hue-rotate(359deg)
    brightness(101%) contrast(105%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.navBar-grey {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #212529;
}

.navLink {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.0625em;
}

.navBar-links {
    justify-content: flex-end;
    text-align: right;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #ffc800;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #ffc800;
}

.userNav
{
  border: 2px solid;
  border-radius: 25px;
  border-color: #ffc800;
}
.section{padding:6rem 16rem}.section.carousel .header{margin-bottom:36px}.section.carousel .carousel-text{height:calc(100% - 51px);display:flex;flex-direction:column;justify-content:space-between;width:50%;text-align:left}.section.carousel .carousel-text .step-btn{margin-bottom:12px;max-width:400px}.section.carousel .carousel-text .step-btn .step-header-wrapper{align-items:flex-start;display:flex}.section.carousel .carousel-text .step-btn .step-header-wrapper h4{margin-right:6px}.section.carousel .carousel-text .step-btn .step-header-wrapper h4.number{margin-top:1px}.section.carousel .carousel-text .step-btn .step-header-wrapper p{color:gray}.section.carousel .carousel-text button{text-align:left;padding:0px}.section.carousel .carousel-item{border-radius:8px;overflow:hidden}.section.carousel .carousel-general{width:50%;display:flex;flex-direction:column-reverse}.section.carousel .carousel-general .carousel-indicators{position:relative;margin-top:12px}.navBar{padding:0px 48px !important;width:100%}.navBar .navLink{margin-right:8px}.main-heading{text-shadow:#000 0px 1px 3px;color:#fff;font-size:4.5rem;font-weight:700;line-height:4.5rem;margin-bottom:4rem;font-family:"Montserrat",sans-serif;font-weight:800;margin-right:auto;margin-left:auto}.btn{border-radius:8px !important}
section#contact {
    background-color: #212529;
    background-image: url(/static/media/map-image.3cd6dd98.png);
    background-repeat: no-repeat;
    background-position: center;
}

section#contact .section-heading {
    color: #fff;
}

.form-control {
    height: auto;
    padding: 1.25rem;
}

section#contact form#contactForm .form-group {
    margin-bottom: 1.5rem;
}

section#contact form#contactForm .form-group-textarea textarea {
    height: 100%;
    min-height: 10rem;
}

section#contact form#contactForm .form-group-textarea {
    height: 100%;
}

.btn-xl {
    padding: 1.25rem 2.5rem;
    font-family: 'Montserrat', sans-serif;   font-size: 1.125rem;
    font-weight: 600;
}
.faq-font {
  font-family: "Montserrat", sans-serif;

  /* font-size: 1.125rem;
  font-weight: 400; */
}

.accordion-button:not(.collapsed) {
  color: black;
}

.accordion-button:not(.collapsed) {
  background-color: gainsboro;
}

.accordion-body {
  background-color: gainsboro;
}

.accordion-button:not(.collapsed)::after {
  -webkit-filter: brightness(2) sepia(1) hue-rotate(60deg);
          filter: brightness(2) sepia(1) hue-rotate(60deg); /* Adjust the values as needed */
}

.service-item {
  position: relative;
}

.service-img {
  display: block;
  width: 100%;
}

.service-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 200, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity ease-in-out 0.25s;
  opacity: 0;
}

.service-item-overlay > * {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.service-item-overlay:hover {
  opacity: 1;
}

.service-item-overlay:hover > * {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.service-item-overlay-img {
  color: #fff;
}

.service-caption {
  padding: 1.5rem 0.5rem;
  text-align: center;
  background-color: #fff;
}

.service-caption-heading {
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
}

.item-title {
  font-size: 1.5rem;
  line-height: 3rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  line-height: 1.2;
}

.item-description {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.btn-light {
  background-color: transparent;
  border-color: transparent;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light:focus-visible,
.btn-light:active:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  outline: none;
}

.btn:hover,
.btn:focus {
  background-color: transparent;
  border-color: transparent;
}

.top-rounded-edge {
  border-radius: 25px 25px 0 0;
}

.bottom-rounded-edge {
  border-radius: 0px 0px 25px 25px;
}

.footer {
  background-color: #212529;
  color: #fff;
  padding: 20px 48px;
  text-align: center;
  width: 100%;
}

.green-line {
  height: 10px;
  width: 100%;
  background: linear-gradient(to right, #ffffff, #4ca982);
  margin: 0;
}

.footer-columns {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-column {
  flex-basis: 45%; /* Adjust the width of the columns as needed */
  text-align: left;
  font-size: 14px;
}

.footer-column .bold-text {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px; /* Double the margin between the bold text and anchors */
  display: block;
}

.footer-column a {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
}

.line {
  border: 1px solid #000;
  margin: 20px 0;
}

.copyright-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.logo-image {
  max-width: 40px;
  height: auto;
}

.social-link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 10px;
  font-size: 14px;
}

.social-icon {
  font-size: 24px; /* Adjust the font size of the icons as needed */
  margin-right: 10px; /* Add spacing between icon and text */
}

.copyright {
  font-size: 14px; /* Adjust the font size for the copyright text */
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f1f1f1;
    padding: 10px;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.table-general-style {
  width: 100%;
  max-width: 100%;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem; /* 24px */
  padding-bottom: 2.5rem; /* 40px */
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.table-style {
  table-layout: fixed;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  color: rgb(107 114 128);
}

.table-head {
  padding: 0.5rem; /* 8px */
}

.table-row {
  border-width: 1px;
  border-color: rgb(34 197 94);
}

.table-header {
  border-width: 1px;
  border-color: rgb(34 197 94);
  padding: 0.5rem;
}

.table-body {
}

.table-data {
  border-width: 1px;
  border-color: rgb(34 197 94);
  padding: 0.5rem; /* 20px */
  overflow-wrap: break-word;
}

.row-is-even {
  background-color: rgb(74, 222, 128, 0.3);
}

.search-container {
  margin-bottom: 1.5rem; /* 24px */
  margin-top: 1.5;
  flex: 1 1;
  align-items: center;
}

.search-text {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  color: rgb(107 114 128);
  margin-right: 1.75rem;
  display: inline;
}

.search-input {
  height: 2rem; /* 32px */
  border-width: 2px;
  border-style: solid;
  border-color: rgb(34 197 94);
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 1rem; /* 16px */
  border-radius: 0.5rem; /* 8px */
}

.resizer {
  display: inline-block;
  background: blue;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.isResizing {
  background: red;
}

.inline {
  display: inline-block;
  padding: 5px;
}

.StatusAsignat {
  color: #fff;
  background-color: #3e41bd;
  border-color: #3e41bd;
}

.StatusReasignat {
  color: #fff;
  background-color: #d80699;
  border-color: #d80699;
}

.StatusRejectat {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}

.StatusInCurs {
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
}

.StatusSolutionat {
  color: #fff;
  background-color: #00b300;
  border-color: #00b300;
}

.dummyBtn{
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}

