.table-general-style {
  width: 100%;
  max-width: 100%;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem; /* 24px */
  padding-bottom: 2.5rem; /* 40px */
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.table-style {
  table-layout: fixed;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  color: rgb(107 114 128);
}

.table-head {
  padding: 0.5rem; /* 8px */
}

.table-row {
  border-width: 1px;
  border-color: rgb(34 197 94);
}

.table-header {
  border-width: 1px;
  border-color: rgb(34 197 94);
  padding: 0.5rem;
}

.table-body {
}

.table-data {
  border-width: 1px;
  border-color: rgb(34 197 94);
  padding: 0.5rem; /* 20px */
  overflow-wrap: break-word;
}

.row-is-even {
  background-color: rgb(74, 222, 128, 0.3);
}

.search-container {
  margin-bottom: 1.5rem; /* 24px */
  margin-top: 1.5;
  flex: 1 1 0%;
  align-items: center;
}

.search-text {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  color: rgb(107 114 128);
  margin-right: 1.75rem;
  display: inline;
}

.search-input {
  height: 2rem; /* 32px */
  border-width: 2px;
  border-style: solid;
  border-color: rgb(34 197 94);
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 1rem; /* 16px */
  border-radius: 0.5rem; /* 8px */
}

.resizer {
  display: inline-block;
  background: blue;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.isResizing {
  background: red;
}

.inline {
  display: inline-block;
  padding: 5px;
}

.StatusAsignat {
  color: #fff;
  background-color: #3e41bd;
  border-color: #3e41bd;
}

.StatusReasignat {
  color: #fff;
  background-color: #d80699;
  border-color: #d80699;
}

.StatusRejectat {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}

.StatusInCurs {
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
}

.StatusSolutionat {
  color: #fff;
  background-color: #00b300;
  border-color: #00b300;
}

.dummyBtn{
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}
