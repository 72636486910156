.section {
  padding: 6rem 16rem;

  &.carousel {
    .header {
      margin-bottom: 36px;
    }
    .carousel-text {
      height: calc(100% - 51px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      text-align: left;
      .step-btn {
        margin-bottom: 12px;
        max-width: 400px;
        .step-header-wrapper {
          align-items: flex-start;
          display: flex;
          h4 {
            margin-right: 6px;
            &.number {
              margin-top: 1px;
            }
          }
          p {
            color: grey;
          }
        }
      }
      button {
        text-align: left;
        padding: 0px;
      }
    }
    .carousel-item {
      border-radius: 8px;
      overflow: hidden;
    }
    .carousel-general {
      width: 50%;
      display: flex;
      flex-direction: column-reverse;
      .carousel-indicators {
        position: relative;
        margin-top: 12px;
      }
    }
  }
}

.navBar {
  padding: 0px 48px !important;
  width: 100%;
  .navLink {
    margin-right: 8px;
  }
}

.main-heading {
  text-shadow: #000 0px 1px 3px;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 4.5rem;
  margin-bottom: 4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  margin-right: auto;
  margin-left: auto;
}

.btn {
  border-radius: 8px !important;
}
