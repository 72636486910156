.navbar {
  position: fixed;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: none;
  background-color: transparent;
  transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  z-index: 1000;
}

.navbar-light .navbar-toggler {
  color: #ffc800;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border-color: #ffc800;
}

.navbar-toggler-icon {
  filter: invert(85%) sepia(15%) saturate(7435%) hue-rotate(359deg)
    brightness(101%) contrast(105%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.navBar-grey {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #212529;
}

.navLink {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.0625em;
}

.navBar-links {
    justify-content: flex-end;
    text-align: right;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #ffc800;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #ffc800;
}

.userNav
{
  border: 2px solid;
  border-radius: 25px;
  border-color: #ffc800;
}