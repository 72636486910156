.service-item {
  position: relative;
}

.service-img {
  display: block;
  width: 100%;
}

.service-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 200, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity ease-in-out 0.25s;
  opacity: 0;
}

.service-item-overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.service-item-overlay:hover {
  opacity: 1;
}

.service-item-overlay:hover > * {
  transform: translateY(0);
}

.service-item-overlay-img {
  color: #fff;
}

.service-caption {
  padding: 1.5rem 0.5rem;
  text-align: center;
  background-color: #fff;
}

.service-caption-heading {
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
}

.item-title {
  font-size: 1.5rem;
  line-height: 3rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  line-height: 1.2;
}

.item-description {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.btn-light {
  background-color: transparent;
  border-color: transparent;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light:focus-visible,
.btn-light:active:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  outline: none;
}

.btn:hover,
.btn:focus {
  background-color: transparent;
  border-color: transparent;
}

.top-rounded-edge {
  border-radius: 25px 25px 0 0;
}

.bottom-rounded-edge {
  border-radius: 0px 0px 25px 25px;
}
