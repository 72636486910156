.faq-font {
  font-family: "Montserrat", sans-serif;

  /* font-size: 1.125rem;
  font-weight: 400; */
}

.accordion-button:not(.collapsed) {
  color: black;
}

.accordion-button:not(.collapsed) {
  background-color: gainsboro;
}

.accordion-body {
  background-color: gainsboro;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(2) sepia(1) hue-rotate(60deg); /* Adjust the values as needed */
}
